import { appAccounting } from '@schuettflix/app-accounting';
import { appOrderDetails } from '@schuettflix/app-order-details';
import { widgetTransportDriver, widgetTransportList } from '@schuettflix/app-transport';
import { accessToken, userLocale, gatewayUrl, baseUrl, baseUrlOrder, baseUrlTransport, baseUrlFulfillment, baseUrlCopernicus as baseUrlConstructionProject, baseUrlOrderApp, } from './sflxAppsSharedStates';
import Toaster from './services/Toaster';
import { getQueryParamManager } from './utils/queryParamManager';
export const appAccountingWC = appAccounting;
export const installSflxApps = ({ analyticsService }) => {
    const queryParamManager = getQueryParamManager();
    const getQueryParam = queryParamManager.getQueryParam.bind(queryParamManager);
    const setQueryParam = queryParamManager.setQueryParam.bind(queryParamManager);
    const getQueryParams = queryParamManager.getQueryParams.bind(queryParamManager);
    const setQueryParams = queryParamManager.setQueryParams.bind(queryParamManager);
    /**
     * Transport Service
     */
    widgetTransportList.install({
        toaster: Toaster,
        hostAppVersion: process.env.VUE_APP_VERSION,
        userLocale,
        accessToken,
        baseUrlMonolith: baseUrl,
        baseUrlTransport: baseUrlTransport,
        getQueryParam,
        setQueryParam,
        getQueryParams,
        setQueryParams,
    });
    widgetTransportDriver.install({
        toaster: Toaster,
        hostAppVersion: process.env.VUE_APP_VERSION,
        userLocale,
        accessToken,
        baseUrlMonolith: baseUrl,
        baseUrlTransport: baseUrlTransport,
        getQueryParam,
        setQueryParam,
        getQueryParams,
        setQueryParams,
    });
    /**
     * Accounting Service
     */
    appAccountingWC.install({
        accessToken,
        gatewayUrl,
        userLocale,
        hostAppVersion: process.env.VUE_APP_VERSION,
        analytics: {
            service: analyticsService,
        },
    });
    /**
     * Order Service
     */
    appOrderDetails.install({
        accessToken,
        userLocale,
        baseUrlMonolith: baseUrl,
        baseUrlOrder,
        baseUrlFulfillment,
        baseUrlConstructionProject,
        baseUrlOrderApp,
    });
};
