import ApiService from './ApiService';
import _get from 'lodash/get';
import store from '@/store';
import LogService from '@schuettflix/util-log';
import router from '@/pages/router';
import * as ConstructionProjectClient from '@/constructionProjects/api/client/http-client';
import * as ReactiveApiData from '@/store/reactiveApiData';
import {
    accessToken,
    baseUrl,
    userLocale,
    baseUrlCopernicus,
    gatewayUrl,
    baseUrlOrder,
    baseUrlFulfillment,
    baseUrlPosition,
    baseUrlTransport,
    marketCode,
    baseUrlOrderApp,
} from '@/sflxAppsSharedStates';
import { queryClient } from '@/reactBridge/queryClient';

const Log = new LogService('services/Api/ApiSetup');

export default function apiSetup(options) {
    // ============================================================
    // Basic API Setup
    // ============================================================
    if (store.getters['platform/apiBaseUrl'] === null) {
        store.commit('platform/setApiBaseUrl', window.apibaseurl);
    }

    ApiService.setBaseUrl(store.getters['platform/apiBaseUrl']);
    ApiService.setAuthorizationHeader(store.getters['user/token']);
    ApiService.setAcceptLanguage(store.getters['i18n/locale']);

    ConstructionProjectClient.setAccessToken(store.getters['user/token']);
    ConstructionProjectClient.setAcceptLanguage(store.getters['i18n/locale']);
    ConstructionProjectClient.setAppVersion(process.env.VUE_APP_VERSION);

    ReactiveApiData.accessToken.value = store.getters['user/token'];
    ReactiveApiData.baseUrl.value = store.getters['platform/apiBaseUrl'];

    // SFLX Apps
    accessToken.value = store.getters['user/token'];
    baseUrl.value = store.getters['platform/apiBaseUrl'];
    baseUrlCopernicus.value = store.getters['platform/constructionProjectApiUrl'];
    gatewayUrl.value = store.getters['platform/gatewayUrl'];
    baseUrlOrder.value = store.getters['platform/orderServiceUrl'];
    baseUrlPosition.value = store.getters['platform/positionServiceUrl'];
    baseUrlFulfillment.value = store.getters['platform/fulfillmentServiceUrl'];
    baseUrlTransport.value = store.getters['platform/transportServiceUrl'];
    baseUrlOrderApp.value = store.getters['platform/genericOrderUrl'];
    userLocale.value = store.state.i18n.locale;
    marketCode.value = store.getters['platform/info']?.market.code;

    // ============================================================
    // Watch for store update to change API values
    // ============================================================
    store.watch(
        state => state.user.token,
        newValue => {
            ApiService.setAuthorizationHeader(newValue);
            ConstructionProjectClient.setAccessToken(newValue);
            ReactiveApiData.accessToken.value = newValue;
            accessToken.value = store.getters['user/token'];
            setTimeout(() => queryClient.invalidateQueries(), 0);
        }
    );

    store.watch(
        state => state.platform.apiBaseUrl,
        newValue => {
            ApiService.setBaseUrl(newValue);
            baseUrl.value = newValue;
            ReactiveApiData.baseUrl.value = newValue;
        }
    );

    store.watch(
        state => state.platform.constructionProjectApiUrl,
        newValue => {
            baseUrlCopernicus.value = newValue;
        }
    );

    store.watch(
        state => state.platform.gatewayUrl,
        newValue => {
            gatewayUrl.value = newValue;
        }
    );

    store.watch(
        state => state.platform.orderServiceUrl,
        newValue => {
            baseUrlOrder.value = newValue;
        }
    );

    store.watch(
        state => state.platform.baseUrlOrderApp,
        newValue => {
            baseUrlOrderApp.value = newValue;
        }
    );

    store.watch(
        state => state.platform.fulfillmentServiceUrl,
        newValue => {
            baseUrlFulfillment.value = newValue;
        }
    );

    store.watch(
        state => state.platform.transportServiceUrl,
        newValue => {
            baseUrlTransport.value = newValue;
        }
    );

    store.watch(
        state => state.i18n.locale,
        newValue => {
            ApiService.setAcceptLanguage(newValue);
            ConstructionProjectClient.setAcceptLanguage(newValue);
            userLocale.value = newValue;
        }
    );

    store.watch(
        state => state.platform.info,
        newValue => {
            marketCode.value = newValue.market.code;
        }
    );

    // ============================================================
    // API interceptor setup for response check
    // ============================================================
    let maintenanceCheckTimeout = null;

    ApiService.axios.interceptors.response.use(
        response => {
            store.commit('platform/setMaintenanceMode', false);
            store.commit('platform/setUpdateRequired', false);
            store.commit('platform/setApiIncompatible', false);
            clearTimeout(maintenanceCheckTimeout);
            return response;
        },
        err => {
            const code = _get(err, 'response.status');

            if (code === 401) {
                store.commit('platform/setMaintenanceMode', false);

                // Check request token against current token to ignore all incoming responses after the first 401
                const authToken = _get(err, ['response', 'config', 'headers', 'Api-Authorization'], null);
                const currentToken = `Bearer ${store.getters['user/token']}`;

                if (authToken === currentToken) {
                    store.dispatch('user/forceLogout').then(() => {
                        router.push({ name: 'login' }).catch(() => {
                            Log.error(`Failed to push to login route after forceLogout on 401 API rejection.`);
                        });
                    });
                }
            }

            // client/backend version mismatch
            if (code === 412 && !store.getters['platform/isUpdateRequired']) {
                store.commit('platform/setApiIncompatible', true);
                const { onVersionMismatch } = options;
                if (onVersionMismatch) {
                    try {
                        onVersionMismatch();
                    } catch (e) {
                        Log.error('Failure in version missmatch callback', err);
                    }
                }
            }

            // 5xx status
            if (code === 503 && !store.getters['platform/isMaintenanceActive']) {
                store.commit('platform/setMaintenanceMode', true);

                maintenanceCheckTimeout && clearTimeout(maintenanceCheckTimeout);
                maintenanceCheckTimeout = setTimeout(() => {
                    store.dispatch('platform/updateInfo');
                }, 3000);
            }

            return Promise.reject(err);
        }
    );

    // ============================================================
    // API request handlers to track start and finish request calls
    // ============================================================
    ApiService.setStartRequestHandler(opts => {
        opts.trackRequest && store.commit('request/startRequest');
    });

    ApiService.setFinishRequestHandler(opts => {
        opts.trackRequest && store.commit('request/finishRequest');
    });
}
